@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.photo-gallery-image-module  {

    figure {

        position: relative;
        margin: 0 auto;

        figcaption {
            // position: absolute;
            bottom: 0;
            background: var(--wp--preset--color--bnpp-green);
            width: 100%;
            padding: 0.5rem;
            color: #fff;
            font-weight: 400;
        }

        img {
            width: 100%;
        }

    }
    
}